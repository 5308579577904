import {DateDiv} from "./DateDiv";
import React, {useEffect, useState} from "react";
import {
  GetAvailableAppointmentsRequest,
  GetAvailableAppointmentsResponse, IApiBusinessProfileProto,
  IProviderProto,
  IProviderServiceProto
} from "../../../../compiled";
import {SendUnauthenticatedRpc} from "../../../../rpcSender";
import './BookingOverlay.css';
import { findProvider } from "../../../../util/ProviderUtil";

interface Props {
  // Assumed these are sorted by day.
  business: IApiBusinessProfileProto; // optional you can
  service: IProviderServiceProto;
  onCancel: () => void;
  onTimeChosen: (timestamp: number) => void;
}

export const AppointmentSlots = (props: Props) => {

  const [appointments, setAppointments] = useState<GetAvailableAppointmentsResponse | null>()
  const [loading, setLoading] = useState(true)
  const [appointmentsError, setAppointmentsError] = useState<string>()

  useEffect(() => {

    setAppointmentsError('');

    SendUnauthenticatedRpc('get_available_appointments',
        GetAvailableAppointmentsRequest.encode(new GetAvailableAppointmentsRequest({
          businessId: props.business.businessId,
          fromTimestampMs: new Date().getTime(), // millis since epoch
          toTimestampMs: new Date().getTime() + (1000 * 60 * 60 * 24 * 6), // the next week
          sku: props.service.sku,
        }))
            .finish())
        .then(responseBytes => {
          let response = GetAvailableAppointmentsResponse.decode(responseBytes);
          if (!response.okay) {
            setAppointmentsError("Error loading appointments")
            return;
          }

          console.log(response)
          setAppointments(response);
        })
        .catch(e => {
          console.log('appointments error', e)
          setAppointmentsError('Error loading appointments: ' + e);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [])

  // The response is just a list of numbers. This categorizes them by date
  // in the local time zone.
  const splitByDate = (dates: Date[] | undefined): Date[][] => {
    if (!dates) {
      return [];
    }

    var subarrays: Date[][] = [];
    var startIndex = 0;
    for (let i = 0; i < dates.length; i++) {

      if (dates[i].getDate() != dates[startIndex].getDate()) {

        // This position in the array has a different date than the last position
        // in the array. Add a new subarray to the return value.
        // Note this is a shallow copy which is very good!
        subarrays.push(dates.slice(startIndex, i));
        startIndex = i;
      }
    }

    if (startIndex < dates.length) {
      subarrays.push(dates.slice(startIndex));
    }

    return subarrays;
  }


  return (<>
    <div className={'BookingOverlayTopRow'}>
      <div className='BookingOverlaySectionMargin'>
        <div className={'HorizontalCenteredFlex'}>
          <div style={{flex: 1}}>Book Appointment</div>
            <img className={'BookingOverlayClose'} onClick={props.onCancel}
                 src={require('../../../../images/icons/close.png')}/>
        </div>
      </div>
    </div>
    <div className={'BookingOverlayBottomRow'}>
      <div className={'BookingOverlayBottomLeft'}>
        <div className='BookingOverlaySectionMargin'>
          <b>{props.service.name}</b>
          <b> ({props.service.lengthMinutes} min) </b>
          <br/>
          <br/>
          {'todo address'}
        </div>
      </div>

      <div className={'BookingOverlayBottomRight'}>
        <div className='BookingOverlaySectionMargin'>
          {loading && 'Loading available times...'}
          {appointmentsError && <div style={{color: 'red'}}>{appointmentsError}</div>}

          {appointments?.availableTimes?.map(value => {
            const providerId = value.providerId;
            const provider = findProvider(props.business.providers, providerId)
            const availableTimes = value.availableTimes
            const dates = availableTimes?.map(value => new Date(value as number));
            const dateSubArrays = splitByDate(dates) || null;

            return <div style={{textAlign: 'start'}}>
              <h3>{provider?.firstName + ' ' + provider?.lastName}</h3>
              {dateSubArrays.map(dateSubArray => {
                return <DateDiv dates={dateSubArray} service={props.service}
                                providerId={'todo provider id'}
                                onTimeSelected={props.onTimeChosen}
                />
              })}
            </div>
          })}
        </div>
      </div>
    </div>
  </>);
}