import {space} from "../compiled";
import IProviderBioProto = space.kenko.proto.IProviderBioProto;

export const findProvider = (providers: IProviderBioProto[] | null | undefined,
                             providerId: string | null | undefined): IProviderBioProto | null => {

  if (!providers) {
    return null;
  }

  const index = findProviderIndex(providers, providerId);
  return index < 0 ? null : providers[index];
}


export const findProviderIndex = (providers: IProviderBioProto[] | null | undefined,
                                  providerId: string | null | undefined): number => {

  if (!providerId || !providers?.length) {
    return -1;
  }

  for (let i = 0; i < providers.length; i++) {
    let provider = providers[i];
    if (provider.providerId == providerId) {
      return i;
    }
  }

  return -1;
}