import React, {useState} from 'react';
import './BookingOverlay.css'
import {IApiBusinessProfileProto, IProviderServiceProto} from "../../../../compiled";
import {AppointmentSlots} from "./AppointmentSlots";
import {ConfirmBooking} from "./ConfirmBooking";

export type Props = {
  provider: IApiBusinessProfileProto,
  service: IProviderServiceProto,
  onCancel: () => void;
}

export const BookingOverlay = (props: Props) => {
  
  // If unset, goes to the appointment slots subpage.
  const [appointmentTime, setAppointmentTime] = useState<number>();
  
  return <div className={'BookingOverlayModal'}>
    <div className={'BookingOverlay'}>

      {!appointmentTime && <AppointmentSlots
          business={props.provider} service={props.service}
          onCancel={props.onCancel} onTimeChosen={setAppointmentTime}
      />}

      {appointmentTime && <ConfirmBooking 
          provider={props.provider} service={props.service}
          appointmentTime={appointmentTime} onCancel={props.onCancel}/>}
      
    </div>
  </div>
}